<template>
  <v-card class="container px-4">
    <v-card-title class="title text-xs-h6 text-sm-h3 justify-md-center justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-avatar color="red"
        class="ml-md-16 text-sm-h4 text-md-h3 text-xs-h6 subheading white--text"
        v-text="step"
      ></v-avatar>
    </v-card-title>

    <v-window touchless class="px-8" v-model="step">
      <v-window-item :value="1">
        <v-form>
          <v-text-field clearable v-model="email" label="Email" :value="email" required
            :error-messages="emailErrors" @blur="$v.email.$touch()"
          ></v-text-field>
          <span class="body-2 grey--text text--darken-3">
            Email будет использоваться в качетсве логина Вашей учетной записи
          </span>

          <v-text-field v-model="password1" class="mt-6" label="Пароль"
            type="password" :value="password1" required
            :error-messages="passwordErrors" @blur="$v.password1.$touch()"
          ></v-text-field>
          <span class="body-2 grey--text text--darken-3">
            Введите пароль Вашей учетной записи
          </span>

          <v-text-field v-model="password2" class="mt-6" label="Подтвердите пароль"
            type="password" :value="password2" :error-messages="passwordErrors"
            required @blur="$v.password2.$touch()"
          ></v-text-field>
          <span class="body-2 grey--text text--darken-3">
            Введите пароль еще раз для подтверждения
          </span>
        </v-form>

        <v-card-actions class="mt-12">
          <v-btn :disabled="step === 1" large text @click="step--" >
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="$v.email.$invalid || $v.password1.$invalid ||
            $v.password2.$invalid" large text
            @click="step++">Далее</v-btn>
        </v-card-actions>
      </v-window-item>

      <v-window-item :value="2">
        <v-form>
          <v-text-field class="mb-4" v-model="name" :value="name" :error-messages="nameErrors" required
            label="Укажите свое имя" @blur="$v.name.$touch()" :counter="30">
          </v-text-field>

          <v-text-field class="mb-4" v-model="surname" :value="surname" :error-messages="surnameErrors" required
            label="Укажите свою фамилию" @blur="$v.surname.$touch()"
            :counter="30">
          </v-text-field>

          <v-text-field class="mb-12" v-model="phone" :value="phone" :error-messages="phoneErrors" required
            label="Укажите номер контактного телефона" @blur="$v.phone.$touch()"
            :counter="20">
          </v-text-field>

            <v-radio-group v-model="parentOrStudent" mandatory row
              :error-messages="radioErrors" required @blur="$v.parentOrStudent.$touch()">
              <div class="body-2 grey--text text--darken-3 mb-6 mb-sm-0 mr-sm-12">Вы регистрируетесь как родитель или как спортсмен клуба?</div>
              <v-radio label="Спортсмен клуба" value="student"></v-radio>
              <v-radio label="Родитель" value="parent"></v-radio>
            </v-radio-group>

          <v-card-actions class="mt-6">
            <v-btn :disabled="step === 1" large text @click="step--" >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="$v.name.$invalid || $v.surname.$invalid ||
              $v.phone.$invalid || $v.parentOrStudent.$invalid"
              text large @click="step++">
              Далее
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-window-item>

      <v-window-item :value="3">
        <div v-if="parentOrStudent === 'parent'" class="text-center">
          <v-img
            class="mt-n4 mb-md-6 mb-2"
            contain
            height="256"
            src="../assets/bg_logo.jpg"
          ></v-img>
          <h3 class="title mt-n2 text-md-h5 font-weight-bold mb-4">
            Добро пожаловать, {{ name }}!
          </h3>

          <div class="mb-md-16 mb-8">
            <v-btn :to="{name: 'Home'}" rounded md-large dark color="black">
              Перейти на главную
            </v-btn>
          </div>

          <span v-if="parentOrStudent === 'parent'" class="mt-md-12 mt-8 text-md-subtitle-1 body-2 grey--text text--darken-3">
            Теперь в приложении можно <span class="font-weight-bold">зарегистрировать Вашего ребенка!</span>
            Для этого нажмите на кнопку ниже. Вы также всегда можете зарегистрировать ребенка на странице профиля.</span>
          <p><v-btn rounded @click="childRegistrationMethod" class="mt-4" color="red" dark>
            Зарегистрировать ребенка
          </v-btn></p>

        </div>

        <v-form v-if="parentOrStudent !== 'parent'">
          <v-text-field class="my-4" v-model="weight" :value="weight" :error-messages="weightErrors" required
            label="Укажите свой вес в килограммах" @blur="$v.weight.$touch()" :counter="3">
          </v-text-field>

          <v-dialog ref="dialog" v-model="modal" :return-value.sync="birthdate" persistent
            width="350px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="birthdate" label="Укажите дату рождения"
                :error-messages="dateError" @blur="$v.birthdate.$touch()"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker :first-day-of-week="0" locale="ru-ru"
                      elevation="15" color="red" v-model="birthdate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text @click="modal = false">
                Отмена
              </v-btn>
              <v-btn text @click="$refs.dialog.save(birthdate)">
                Сохранить
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-file-input accept="image/png, image/jpeg, image/bmp" v-model="image"
            placeholder="Загрузите фото профиля" class="mb-2"
            prepend-icon="mdi-camera"
            label="Фото профиля"
          ></v-file-input>

          <v-select v-model="instructor" :items="$store.state.instructors"
            :error-messages="selectErrors" class="my-6"
            label="Укажите своего тренера / инструктора"
            required @change="$v.instructor.$touch()" @blur="$v.instructor.$touch()">
          </v-select>

          <div class="mt-12">
            <v-checkbox v-model="checkbox" :error-messages="checkboxErrors"
              label="Даете ли Вы согласие на обработку указанных персональных данных?"
              required @blur="$v.checkbox.$touch()" @change="$v.checkbox.$touch()">
              <template v-slot:label>
                <div>
                  Даете ли Вы согласие на обработку
                  <a target="#" @click="dialog = true">
                    персональных данных
                  </a>
                    <v-dialog v-model="dialog" max-width="350">
                      <v-card>
                        <v-card-title class="headline">
                          Обработка персональных данных
                        </v-card-title>
                        <v-card-text class="grey--text text--darken-3">
                          Хранение, систематизация и обновление персональных данных осуществляется исключительно в целях данного приложения,
                          а также в рамках информирования тренерского состава ILMMA.
                          Персональные данные не передаются третьим лицам.
                          Обработка персональных данных осуществляется в соответствии с действующим законодательством Российской Федерации.
                          Срок действия данного согласия не ограничен. Согласие может быть отозвано в любой момент по Вашему заявлению (письменному или устному).
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="white--text grey darken-4" @click="dialog = false">
                            Принять
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  ?
                </div>
              </template>
            </v-checkbox>
          </div>

          <v-card-actions class="mt-3">
            <v-btn :disabled="step === 1" large text @click="step--" >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="$v.weight.$invalid || $v.birthdate.$invalid ||
              $v.instructor.$invalid || $v.checkbox.$invalid" text large
              @click="step++">Далее</v-btn>
          </v-card-actions>
        </v-form>
      </v-window-item>

      <v-window-item :value="4">
        <div v-if="!childRegistration" class="text-center">
          <v-img
            class="mt-n4 mb-md-6 mb-2"
            contain
            height="256"
            src="../assets/bg_logo.jpg"
          ></v-img>
          <h3 class="title mt-n2 text-md-h5 font-weight-bold mb-4">
            Добро пожаловать, {{ name }}!
          </h3>

          <v-spacer></v-spacer>

          <div class="mb-md-16 mb-16 mt-16">
            <v-btn v-if="!$store.getters.isAuthenticated" @click="EditFormSubmit" rounded md-large dark>
              Перейти на главную
            </v-btn>
            <v-btn v-else @click="EditFormSubmit" rounded md-large dark>
              Принять изменения
            </v-btn>
          </div>
        </div>

        <v-form v-if="childRegistration">
          <v-text-field class="mb-2" v-model="childName" :value="childName" :error-messages="childNameErrors" required
            label="Укажите имя ребенка" @blur="$v.childName.$touch()" :counter="30">
          </v-text-field>

          <v-text-field class="mb-6" v-model="childSurname" :value="childSurname" :error-messages="childSurnameErrors" required
            label="Укажите фамилию ребенка" @blur="$v.childSurname.$touch()"
            :counter="30">
          </v-text-field>

          <v-text-field class="mb-4" v-model="weight" :value="weight" :error-messages="weightErrors" required
            label="Укажите вес ребенка в килограммах" @blur="$v.weight.$touch()" :counter="3">
          </v-text-field>

          <v-dialog ref="dialog" v-model="modal" :return-value.sync="childBirthdate" persistent
            width="350px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="birthdate" label="Укажите дату рождения ребенка"
                :error-messages="dateError" @blur="$v.birthdate.$touch()"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker :first-day-of-week="0" locale="ru-ru"
                  elevation="15" color="red" v-model="birthdate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text @click="modal = false">
                Отмена
              </v-btn>
              <v-btn text @click="$refs.dialog.save(birthdate)">
                Сохранить
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-file-input accept="image/png, image/jpeg, image/bmp" v-model="image"
            class="mb-2" prepend-icon="mdi-camera" label="Загрузите фото профиля"
          ></v-file-input>

          <v-select v-model="instructor" :items="$store.state.instructors"
            :error-messages="selectErrors" class="mt-4 mb-4"
            label="Укажите своего инструктора"
            required @change="$v.instructor.$touch()" @blur="$v.instructor.$touch()">
          </v-select>

          <v-checkbox v-model="checkbox" :error-messages="checkboxErrors"
            required @blur="$v.checkbox.$touch()" @change="$v.checkbox.$touch()">
            <template v-slot:label>
              <div>
                Даете ли Вы согласие на обработку
                <a target="_blank" @click="dialog = true">
                  персональных данных
                </a>
                  <v-dialog v-model="dialog" max-width="350">
                    <v-card>
                      <v-card-title class="headline">
                        Обработка персональных данных
                      </v-card-title>
                      <v-card-text class="grey--text text--darken-3">
                        Хранение, систематизация и обновление персональных данных осуществляется исключительно в целях данного приложения,
                        а также в рамках информирования тренерского состава ILMMA.
                        Персональные данные не передаются третьим лицам.
                        Обработка персональных данных осуществляется в соответствии с действующим законодательством Российской Федерации.
                        Срок действия данного согласия не ограничен. Согласие может быть отозвано в любой момент по Вашему заявлению (письменному или устному).
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="white--text grey darken-4" @click="dialog = false">
                          Принять
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                ?
              </div>
            </template>
          </v-checkbox>

          <v-card-actions class="mt-3">
            <v-btn :disabled="step === 1" large text @click="step--" >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="$v.name.$invalid || $v.surname.$invalid ||
              $v.weight.$invalid || $v.birthdate.$invalid ||
              $v.instructor.$invalid || $v.checkbox.$invalid" text large
              @click="step++">Далее</v-btn>
          </v-card-actions>
        </v-form>

      </v-window-item>

      <v-window-item :value="5">
        <v-img
          class="mb-md-6 mb-2"
          contain
          height="256"
          src="../assets/bg_logo.jpg"
        ></v-img>
        <div class="text-center">
          <h3 class="title text-md-h5 font-weight-bold my-12">
            Спасибо за регистрацию, {{ name }}!
          </h3>

          <v-btn @click="EditFormSubmit" rounded md-large dark class="mb-12">
            Перейти на главную
          </v-btn>
        </div>
      </v-window-item>

    </v-window>

  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { numeric, required, sameAs, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password1: { required, minLength: minLength(8) },
    password2: { sameAsPassword: sameAs('password1') },
    name: { required, maxLength: maxLength(30) },
    surname: { required, maxLength: maxLength(30) },
    childName: { required, maxLength: maxLength(30) },
    childSurname: { required, maxLength: maxLength(30) },
    phone: { required, numeric, maxLength: maxLength(20) },
    birthdate: { required },
    parentOrStudent: { required },
    checkbox: { checked (val) {return val;} },
    instructor: { required },
    weight: { required, numeric, maxLength: maxLength(3) }
  },
  data() {
    return {
      userId: this.$store.state.user.userId,
      email: this.$store.state.user.userEmail,
      password1: this.$store.state.user.userPassword,
      password2: this.$store.state.user.userPassword,
      name: this.$store.state.user.userName,
      surname: this.$store.state.user.userSurname,
      childName: '',
      childSurname: '',
      phone: this.$store.state.user.userPhone,
      birthdate: this.$store.state.user.userBirthDate,
      parentOrStudent: null,
      weight: this.$store.state.user.userWeight,
      image: null,
      imageURL: null,
      instructor: this.$store.state.user.userInstructor,
      step: 1,
      modal: false,
      checkbox: false,
      dialog: false,
      childRegistration: false,
    }
  },
  computed:{
    passwordValidation() {
      return this.password1 === this.password2;
    },
    currentTitle () {
      switch (this.step) {
        case 1: return 'Укажите логин и пароль'
        case 2: return 'Введите контактные данные'
        case 3: return this.parentOrStudent === 'student' ? 'Карточка спортсмена' : `Вы зарегистрированы!`
        case 4: return this.childRegistration === true ? 'Регистрация ребенка' : `Вы зарегистрированы!`
        default: return 'Вы зарегистрированы!'
      }
    },
    childNameErrors() {
      const errors = [];
      if (!this.$v.childName.$dirty) return errors;
      !this.$v.childName.required && errors.push('Поле является обязательным для заполнения');
      !this.$v.childName.maxLength && errors.push('Введите не больше 30 символов');
      return errors
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push('Поле является обязательным для заполнения');
      !this.$v.name.maxLength && errors.push('Введите не больше 30 символов');
      return errors
    },
    dateError() {
      const errors = [];
      if (!this.$v.birthdate.$dirty) return errors;
      !this.$v.birthdate.required && errors.push('Укажите дату рождения');
      return errors
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.surname.$dirty) return errors;
      !this.$v.surname.required && errors.push('Поле является обязательным для заполнения');
      !this.$v.surname.maxLength && errors.push('Введите не больше 30 символов');
      return errors
    },
    childSurnameErrors() {
      const errors = [];
      if (!this.$v.childSurname.$dirty) return errors;
      !this.$v.childSurname.required && errors.push('Поле является обязательным для заполнения');
      !this.$v.childSurname.maxLength && errors.push('Введите не больше 30 символов');
      return errors
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push('Поле является обязательным для заполнения');
      !this.$v.phone.numeric && errors.push('Пожалуйста, введите в это поле только цифры без каких-либо знаков');
      !this.$v.phone.maxLength && errors.push('Введите не больше 20 символов');
      return errors
    },
    emailErrors () {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Пожалуйста, укажите корректный адрес электронной почты');
      !this.$v.email.required && errors.push('Поле является обязательным для заполнения');
      return errors
    },
    passwordErrors () {
      const errors = [];
      if (!this.$v.password1.$dirty) return errors;
      !this.$v.password1.minLength && errors.push('Пароль должен содержать не менее 8 символов. Желательно, чтобы пароль содержал строчные и заглавные буквы, а также цифры');
      !this.$v.password1.required && errors.push('Пожалуйста, введите пароль');
      !this.$v.password2.sameAsPassword && errors.push('Пароли должны совпадать');
      return errors
    },
    radioErrors() {
      const errors = [];
      if (!this.$v.parentOrStudent.$dirty) return errors;
      !this.$v.parentOrStudent.required && errors.push('Обязательно для выбора');
      return errors
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push('Без подтверждения данного пункта регистрация невозможна');
      return errors
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.instructor.$dirty) return errors;
      !this.$v.instructor.required && errors.push('Необходимо выбрать Вашего инструктора');
      return errors
    },
    weightErrors() {
      const errors = [];
      if (!this.$v.weight.$dirty) return errors;
      !this.$v.weight.required && errors.push('Поле является обязательным для заполнения');
      !this.$v.weight.numeric && errors.push('Пожалуйста, введите в это поле только цифры');
      !this.$v.weight.maxLength && errors.push('Введите не больше 3 символов');
      return errors
    }
  },
  methods: {
    childRegistrationMethod() {
      this.childRegistration = true
      this.step++
    },
    onFileChange(e) {
      // const files = e.target.files || e.dataTransfer.files;
      const file = e.target.files[0];
      if (!file.name)
        return;
      this.image = file;
      this.imageUrl = URL.createObjectURL(file);
    },
    async EditFormSubmit() {
      let response = await this.$store.dispatch('editFormSubmit', {
        userId: this.userId,
        userEmail: this.email,
        userPassword: this.password2,
        userName: this.name,
        userSurname: this.surname,
        userBirthDate: this.birthdate,
        userParentOrStudent: this.parentOrStudent,
        userWeight: this.weight,
        userPhone: this.phone,
        userImage: this.imageUrl,
        userInstructor: this.instructor
      });
      if (response && this.passwordValidation) {
        this.$router.replace({ name: "Home" });
      } else {
        this.$router.replace({ name: "Error" });
      }
    }
  }
}
</script>
